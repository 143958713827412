@font-face {
    font-family: 'Helvetica-Regular';
    src: url('./Assets/HELVETICA.TTF') format('truetype');
  }

@font-face {
    font-family: 'Tingker Kelly-Regular';
    src: url('./Assets/TingkerKelly.otf') format('opentype');
  }


.desktop {
    background-color: #fcedce;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.desktop3 .p {
        
    overflow: auto;    
}

.desktop .div {
    background-color: #fcedce;
    height: 60vh;
    position: relative;
    width: 100vw;
    justify-content: center; /* Aligns children horizontally in the center */
    align-items: center;
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    
 
}

.desktop .text-wrapper-2 {
    color: #ef121f;
    font-family: "Tingker Kelly-Regular", Helvetica;
    font-size: 64px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    white-space: nowrap;
    margin-top: 15vh;
}

.desktop .servicesgroup-instance {
    display:flex;
    justify-content: space-between; /* Distribute space equally between child divs */
    align-items: center; /* Align items vertically centered (optional) */
    width: 70%; /* Adjust as needed */
    max-width:70%;
    padding: 10px; /* Optional padding */
    box-sizing: border-box; 
    margin-bottom: 20px;
   
    
}

.desktop .overlap {
    flex: 1; /* Allow each child to grow equally */
    text-align: center; /* Center text horizontally within each child */
    margin: 0 10px; /* Optional margin to add space around each child */
    padding: 10px; /* Optional padding inside each child */
    border-radius: 45px;
   
 

    top: 292px;
    width: 20vw;
}

.desktop .servicesgroup-2 {
    border: 3px solid;
    border-color: #2f6db7;
    border-radius: 45px;
    box-shadow: 0px 4px 4px #00000040;
    height: 50vh;
    left: 0;
    position: relative;
    top: 0;
    width: 18vw;
    display:flex;
    flex-direction: column;
    
    align-items: center;
}

.desktop .frontend-development-2 {
    color: #2869b5;
    font-family: "Helvetica-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
   
    letter-spacing: -1.68px;
    line-height: normal;
   padding-top: 27px;
    

    max-width:100%;
}

.desktop .rectangle {
    background-color: #ef121f;
    border: 3px solid;
    border-color: #2f6db7;
    border-radius: 45px 45px 0px 0px;
    box-shadow: 0px 4px 4px #00000040;
    height: 40%;
  
   
    top: 19px;
    width: 16vw;

    display: flex; /* Enable flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative;
    padding:10px;
    box-sizing: border-box;
}

.desktop .building-seamless {
    color: #000000;
    font-family: "Helvetica Light-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 34px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 464px;
    width: 288px;
}

.desktop .figma {
    height: auto;
    
    position: relative;
  
   
    width: 65%;
}

.desktop .overlap-2 {

    flex: 1; /* Allow each child to grow equally */
    text-align: center; /* Center text horizontally within each child */
    margin: 0 10px; /* Optional margin to add space around each child */
    padding: 10px; /* Optional padding inside each child */
    
    border-radius: 45px;
 
    
    
    top: 292px;
    width: 20vw;
}

ul {
    list-style-type: disc; /* Use disc for default bullet points */

   
    text-align: left; 
    font-size: 1.2em;
    color: #2869b5;
    font-family: "Helvetica-Regular", Helvetica
}

.desktop .overlap-3 {
    flex: 1; /* Allow each child to grow equally */
    text-align: center; /* Center text horizontally within each child */
    margin: 0 10px; /* Optional margin to add space around each child */
    padding: 10px; /* Optional padding inside each child */
    border-radius: 45px;
  
    

    top: 292px;
    width: 20vw;
}

.desktop .blender {
    height: auto;  
    position: relative;
    width: 65%;
}






@media only screen and (max-width: 450px){

    .desktop .div{
        height: auto ;
    }
    .desktop .text-wrapper-2 {
        
        font-size: 32px;
        
    }

    .desktop .servicesgroup-instance {
        
        max-width: 80%;
        width: 80%;
        flex-direction: column;
    }

    .desktop .overlap-3 {
        width: 90%;
    }
    .desktop .overlap-2 {
        width: 90%;
    }

    .desktop .overlap {
        width: 90%;
    }

    .desktop .rectangle { 
        height: 40%;  
        width: 90%; 
        top: 13px; 
    }

    .desktop .servicesgroup-2 {
        width:90%;
        height: auto;
    }

    .desktop .frontend-development-2 {
        font-size: 16px;
        letter-spacing: -1px;
    }

    ul {
        font-size: 1em;
    }

    .desktop3 .p {
        
        overflow: auto;
        max-width:100%;
        padding: 5px;
        box-sizing: border-box;
        
    }

    .desktop3 .text-wrapper {
        font-size: 32px;
        padding-top: 15px;
    }

    .desktop3 .frame-2 {
        max-height: 50vh;
        max-height: 200px;
    }

    .desktop3 .frame-3 {
        max-height: 50vh;
        max-height: 200px;
    }

    .desktop3 .text-wrapper-title {
        padding-top: 20px;
    }


 }
