@font-face {
    font-family: 'Helvetica-Regular';
    src: url('./Assets/HELVETICA.TTF') format('truetype');
  }
  
.project-container {
    width: calc(25% - 10px); /* 25% width minus the gap to fit 4 in one row */
  height: 200px; /* Adjust height as needed */
  border-radius: 30px;
  background: #e0e0e073;
  margin: 10px 0; /* Vertical margin for mobile view */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
  
    
}

.project-image {
    width:100%;
    height:100%;
    object-fit: cover;
    border-radius: 30px 30px 0 0;
    
    
}

.desc {
    display:flex;
    flex-direction: row;
    width: 100%;
    height: 33.33%; /* Cover bottom third */
    border: 2px solid #ccc; /* Border all around */
    border-top: none; /* No bottom border */
    border-radius: 0 0 20px 20px; /* Rounded corners at the bottom to match .project-container */
    background: rgba(19, 41, 61,0.5);
    box-sizing: border-box;
    overflow:hidden;


}

p{
    margin-top:4px;
    text-align: left;
    color: #E6E6E9;
    font-family: 'Helvetica-Regular';
    margin-left:10px;
    font-size:0.7em;
}

.project-title {
    color:#E6E6E9;
    text-align:left;
    margin-left:10px;
    font-family: 'Helvetica-Regular';
    margin-bottom:0px;
    font-size: 1em;
   

}

.banner{
    width: 100%;
  height: 66.67%; /* Cover top two-thirds */
  background: #e0e0e0; /* Match the background of the container */
  border-top-left-radius: 30px; /* Match the top border radius */
  border-top-right-radius: 30px; /* Match the top border radius */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.project-category {
    position: absolute;
    top: 10px;
    left: 10px;
    background: rgba(95, 95, 95, 0.4); /* Translucent white background */
    backdrop-filter: blur(5px); /* Blurry effect */
    border: 2px solid #E6E6E9; 
    border-radius: 20px; /* Oval shape */
    padding: 5px 10px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    transition: background 0.3s ease;
    color:#E6E6E9;
  }
  
  .project-category:hover {
    background: #D1CCDC; /* Slightly less translucent on hover */
    color:#13293D;
  }


  .add-button{
    width: 40px; /* Square button */
  height: 40px; /* Square button */
  background: rgba(255, 255, 255, 0.6); /* Translucent white background */
  backdrop-filter: blur(5px); /* Blurry effect */
  border: none;
  border-radius: 8px; /* Rounded corners */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #333;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px; /* Drop shadow */
  padding:10px;
  margin:10px;
  margin-left:auto;
  
  }

  .add-button:hover {
    background: #D1CCDC; 

  }

  .desc-cont{
    display:flex;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    .project-container{
      width: auto;

    }
  }