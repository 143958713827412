@font-face {
    font-family: 'Mestika';
    src: url('./Assets/Mestika.otf') format('opentype');
  }

@font-face {
    font-family: 'Nativity';
    src: url('./Assets/Nativity.otf') format('opentype');
  }

  /* Add this to your CSS file */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .full-image {
    max-width: 90%;
    max-height: 90%;
  }
  
  
.allprojects{
    padding:10px;
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}


.allprojects > * {
    flex: 1 1 calc(28.33% - 20px); /* Ensures each item takes up 1/3 of the container width minus the gap */
    max-width: calc(28.33% - 20px); /* Ensures items don't grow beyond 1/3 of the container width minus the gap */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  }

.section-3{
    display: flex;
    flex-direction:column;
  
    padding: 7vh;
   background-color: rgba(19,41,61,0.4);
   margin-top: 10vh;
   margin-left: 10vw;
   margin-right:10vw;
   border-radius: 30px;
   padding: 20px; /* Add padding to create space inside the box */

  /* Neumorphic shadow effect */
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2),
              -8px -8px 16px rgba(255, 255, 255, 0.8);

  /* Gradient border */
  border: none; /* Remove default border */
  background-clip: padding-box; /* Clip background to padding box */
  border-radius: 20px; /* Same as background */
   

}

.nav-button {
    cursor: pointer;
    width: 30px;
    height: 30px;
    flex: 0 0 auto; 
    margin:1%;
    color: #D1CCDC;
    padding: 2vw;
}

.nav-button:hover {
    transform: scale(1.3);
    opacity: 0.8;
    color: #E0CA3C;
}


.gal{
    text-align: left;
    
    color: white;
    font-family: 'Helvetica-Light';
    font-size: 48px;
    margin-bottom: 0px;
    margin-right: 10px;
    margin-top: 0px;
    margin-left:8vw;
   
}

span{
    color: white;
    text-align: left;
    font-family: 'Mestika';
    font-weight:100;
    font-size: 52px;
    margin-top: 0px;
 
}

.gallery-title{
    display:flex;
    font-family: 'Helvetica-Light';
    font-size:48px;
    margin-top:5vh;
    
    

}

.text-bit{
    color: white;
    font-size: 16px;
    max-width: 30vw;
    font-family: 'Helvetica-Light';
    font-weight: 200;
    margin-left:8vw;
    margin-bottom: 10vh;


}

@media  (max-width: 768px)  {
    .gallery-title{
        font-size: 24px;
        margin-top:5vh;
    }
    span{
        font-size:24px;
    }
    .gal{
        margin-right: 5px;
        font-size: 24px;
    }
    .text-bit{
       
        font-size: 12px;
        max-width:50vw;
    
    }
    .allprojects{
        flex-direction:column ;
    }

    .allprojects > * {
        flex: 1 1 100%; /* Each item takes up the full width */
        max-width: 100%; /* Ensures items don't grow beyond 100% of the container width */
    }

   
    
}